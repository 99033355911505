import {
  LICENSE_LOADING, GET_LICENSES_CUSTOMER, GET_DETAIL_LICENSES_CUSTOMER,
  CLEAR_LICENSES_CUSTOMER, SET_CHIPS_FILTERS_LICENSES, CHANGE_CHIPS_FILTERS_LICENSES,
  ADD_LICENSE_CUSTOMER, LICENSE_SHOW_MESSAGE, GET_CUSTOMER_INFORMATION_LICENSE,
  GET_LICENSE_INFORMATION, GET_MANAGERS,
} from '../types/license.type';

const INITIAL_STATE = {
  loading: false,
  detailLicenses: [],
  licenseCustomer: [],
  chipsFiltersOptions: [],
  licenseCustomerRowsNum: null,
  nextSkipLicense: null,
  addLicenseCustomer: [],
  licenseShowMessageAlert: undefined,
  customerServiceInfoLicense: {},
  getLicenseInformation: [],
  gestorType: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LICENSES_CUSTOMER:
      return {
        ...state,
        licenseCustomer: action.payload,
        licenseCustomerRowsNum: action.rownums,
        nextSkipLicense: action.nextSkipLicense,
      };
    case GET_DETAIL_LICENSES_CUSTOMER:
      return {
        ...state,
        detailLicenses: action.payload,
      };
    case SET_CHIPS_FILTERS_LICENSES:
      return {
        ...state,
        chipsFiltersOptions: action.payload,
      };
    case CHANGE_CHIPS_FILTERS_LICENSES:
      return {
        ...state,
        chipsFiltersOptions: action.payload,
      };
    case CLEAR_LICENSES_CUSTOMER:
      return {
        ...state,
        detailLicenses: action.payload,
      };
    case ADD_LICENSE_CUSTOMER:
      return {
        ...state,
        addLicenseCustomer: action.payload,
      };
    case LICENSE_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case LICENSE_SHOW_MESSAGE:
      return {
        ...state,
        licenseShowMessageAlert: action.payload,
      };
    case GET_CUSTOMER_INFORMATION_LICENSE:
      return {
        ...state,
        customerServiceInfoLicense: action.payload,
      };
    case GET_LICENSE_INFORMATION:
      return {
        ...state,
        getLicenseInformation: action.payload,
      };
    case GET_MANAGERS:
      return {
        ...state,
        gestorType: action.payload,
      };
    default:
      return state;
  }
};
